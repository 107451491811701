<template>
  <q-page class="q-ma-md">
    <span class="text-h6">{{ loja.NOME }}</span> <br />
    <span>{{ loja.WHATSAPP }}</span> <br />
    <br />

    <q-card>
      <q-card-section>
        <strong>Detalhes da venda </strong> <br /><br />
        Nº: {{ object.HASH }}
        <br />
        <span> Cliente: {{ object.CLIENTE }} </span> <br />
        <span> Data: {{ object.DATA }} </span> <br />
        <strong v-if="object.CONDICIONAL" class="text-negative">
          Venda condicional ate {{ object.PRAZO_CONDICIONAL }} <br />
        </strong>
        <br />
        <strong>Produtos</strong>
        <div v-for="produto in object.produtosVendas" :key="produto.ID">
          <span
            >{{ produto.QUANTIDADE }}x{{ produto.NOME }} - R${{
              produto.VALOR
            }}
            | TOTAL R${{ produto.TOTAL }}</span
          >
          <br />
        </div>
        <br />

        <span>Sub Total R$: {{ object.SUB_TOTAL }}</span> <br />
        <span class="text-negative">Desconto R$: {{ object.DESCONTO }}</span
        ><br />
        <span class="text-positive">Total R$: {{ object.TOTAL }}</span> <br />
        <span>Venda {{ object.TIPO_PAGAMENTO }}</span>
        <br />
        <span>{{ object.PAGO ? "Venda paga" : "Venda em aberto" }}</span>
        <br />
      </q-card-section>
    </q-card>
    <br />
    <div class="text-center">
      <a
        href="http://lineasoft.com.br/mini-pdv"
        target="_blank"
        rel="noopener noreferrer"
        >Conheça nosso sistema de venda Mini PDV</a
      >
    </div>
  </q-page>
</template>
<script>
import baseApi from "src/api/base/base.api";
import crediarioApi from "src/api/crediario/crediario.api";
import vendasApi from "src/api/vendas/vendas.api";

export default {
  components: {},
  name: "Pedido",
  data() {
    return {
      object: {
        TOTAL: 0,
        PAGO: 1,
        DATA: "",
        ID_CLIENTE: "",
        TIPO_PAGAMENTO: "À VISTA",
        HASH: "",
        produtosVendas: [],
        TOTAL: 0,
        CLIENTE: "",
        SUB_TOTAL: 0,
        DESCONTO: 0,
        PARCELAS: 1,
        VALOR_PARCELA: 0,
        PIX: 0,
        DINHEIRO: 0,
        CARTAO_CREDITO: 0,
        CARTAO_DEBITO: 0,
        PARCELAS_CARTAO: 1,
        CONDICIONAL: false,
        PRAZO_CONDICIONAL: "",
      },
      tableName: "vendas",
      clientes: [],
      edit: false,
      categorias: ["À VISTA", "CREDIÁRIO", "DUPLICATA"],
      produtos: [],
      showModal: {
        produtos: false,
        parcelas: false,
        cliente: false,
        troco: false,
        clientes: false,
      },
      isMobile: false,
      loading: false,
      parcelas: [],
      limite: {
        valor: 0,
        prazo: 14,
      },
      showClientes: false,
      loja: JSON.parse(localStorage.getItem("loja")),
      valorPago: "",
      troco: 0,
    };
  },

  async created() {
    this.checkDevice();
    await this.getClientes();

    const venda = JSON.parse(localStorage.getItem("venda"));
    if (venda) {
      this.object = venda;
    }

    await this.getProdutos();

    if (this.$route.params.id) {
      this.edit = true;
      await this.getVenda();
      await this.getProdutosVenda();

      //this.totalVenda();
      if (this.edit) {
        this.object.DATA = this.object.DATA.substring(0, 10);
        this.object.PRAZO_CONDICIONAL = this.object.PRAZO_CONDICIONAL.substring(
          0,
          10
        );
      } else {
        // this.object.mes = new Date()
        //   .toLocaleString("pt-br", { month: "long" })
        //   .toUpperCase();
        this.object.DATA = new Date().toISOString().split("T")[0];
      }
      if (this.object.ID_CLIENTE) {
        this.showClientes = true;
      }
    } else {
      this.edit = false;
      this.object.HASH = new Date().getTime();
      this.object.DATA = new Date().toISOString().split("T")[0];
      this.object.PRAZO_CONDICIONAL = new Date().toISOString().split("T")[0];
    }
  },
  methods: {
    checkDevice() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
    async getClientes() {
      await baseApi.get("clientes", "NOME").then((result) => {
        this.clientes = [];
        result.data.forEach((cliente) => {
          this.clientes.push({
            ID_CLIENTE: cliente.ID,
            NOME: cliente.NOME,
            LIMITE: cliente.LIMITE,
            PRAZO: cliente.PRAZO,
          });
        });
      });
    },
    async getProdutosVenda() {
      this.loading = true;
      await vendasApi
        .getByHash("produtos_vendas", this.object.HASH)
        .then(async (result) => {
          this.object.produtosVendas = await result.data;
          this.loading = false;
        })
        .catch((err) => {
          ShowToastMixin.showToast(
            "Houve um erro ao buscar seus produtos",
            "negative"
          );
          this.loading = false;
        });
    },
    async saveReceita() {
      try {
        if (
          ((this.object.TIPO_PAGAMENTO === "CREDIÁRIO" ||
            this.object.TIPO_PAGAMENTO === "DUPLICATA") &&
            !this.object.ID_CLIENTE) ||
          (this.object.CONDICIONAL && !this.object.CLIENTE)
        ) {
          ShowToastMixin.showToast(
            "É obrigatório informar cliente para este tipo de venda",
            "warning"
          );
          return;
        }

        if (this.object.produtosVendas?.length < 1) {
          ShowToastMixin.showToast(
            "Adicione produtos para realizar a venda",
            "warning"
          );
          return;
        }

        //this.object.TOTAL = this.totalVenda;
        delete this.object.NOME;
        // this.object.produtosVendas.forEach((produto) => {
        //   delete produto.CATEGORIA;
        //   // delete produto.IMAGEM;
        //   // delete produto.IMAGEM_URL;
        //   delete produto.INDEX;
        //   delete produto.ESTOQUE;
        //   delete produto.FABRICANTE;
        //   delete produto.CUSTO;
        // });

        this.totalVenda();

        if (this.edit) {
          this.update();
          return;
        }
        this.insert();
      } catch (error) {
        console.log(error);
      }
    },
    async insert() {
      this.loading = true;

      const hashExist = await vendasApi
        .getByHash("vendas", this.object.HASH)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });

      if (hashExist.length) {
        ShowToastMixin.showToast(
          `Atenção! Parece que já existe uma venda com o mesmo número. Isso deve ser um erro do sistema! Atualize a página e faça venda novamente ou entre em contato com o suporte`,
          "negative"
        );
        this.loading = false;
        return;
      } else {
        this.object.PRAZO = this.limite.prazo;
        if (
          this.object.TIPO_PAGAMENTO === "À VISTA" &&
          !this.object.CONDICIONAL
        ) {
          this.valorPago = 0;
          this.troco = 0;
          this.showModal.troco = true;
          this.object.DINHEIRO = this.object.TOTAL;
          this.object.PIX = 0;
          this.object.CARTAO_CREDITO = 0;
          this.object.CARTAO_DEBITO = 0;
          return;
        } else {
          this.object.produtosVendas.forEach((produto) => {
            delete produto.CATEGORIA;
            delete produto.IMAGEM;
            delete produto.IMAGEM_URL;
            delete produto.INDEX;
            delete produto.ESTOQUE;
            delete produto.FABRICANTE;
            delete produto.CUSTO;
            produto.HASH = this.object.HASH;
          });
          await vendasApi
            .insert(this.tableName, this.object)
            .then(async () => {
              ShowToastMixin.showToast(
                "Venda realizada com sucesso",
                "positive"
              );
              if (
                this.object.TIPO_PAGAMENTO === "CREDIÁRIO" ||
                this.object.TIPO_PAGAMENTO === "DUPLICATA"
              ) {
                this.openModalParcelas();
              } else {
                if (this.object?.CLIENTE && !!this.object.ID_CLIENTE) {
                  this.showModal.cliente = true;
                } else {
                  this.backToList();
                  if (this.loja.IMPRESSAO_VENDA) {
                    this.openRecibo();
                  }
                  localStorage.removeItem("venda");
                }
              }
            })
            .catch((error) => {
              ShowToastMixin.showToast(error.message, "negative");
              this.loading = false;
            });
        }
      }
    },
    async update() {
      this.loading = true;
      this.object.PRAZO = this.limite.prazo;
      if (
        this.object.TIPO_PAGAMENTO === "À VISTA" &&
        !this.object.CONDICIONAL
      ) {
        this.valorPago = 0;
        this.troco = 0;
        this.object.DINHEIRO = this.object.TOTAL;
        this.object.PIX = 0;
        this.object.CARTAO_CREDITO = 0;
        this.object.CARTAO_DEBITO = 0;
        this.showModal.troco = true;
        return;
      } else {
        this.object.produtosVendas.forEach((produto) => {
          delete produto.CATEGORIA;
          delete produto.IMAGEM;
          delete produto.IMAGEM_URL;
          delete produto.INDEX;
          delete produto.ESTOQUE;
          delete produto.FABRICANTE;
          delete produto.CUSTO;
          produto.HASH = this.object.HASH;
        });
        await vendasApi
          .update(this.tableName, this.object)
          .then(async () => {
            ShowToastMixin.showToast("Venda alterada com sucesso", "positive");
            if (
              this.object.TIPO_PAGAMENTO === "CREDIÁRIO" ||
              this.object.TIPO_PAGAMENTO === "DUPLICATA"
            ) {
              this.openModalParcelas();
            } else {
              localStorage.removeItem("venda");
              await this.backToList();
            }
          })
          .catch((error) => {
            ShowToastMixin.showToast(error.message, "negative");
            this.loading = false;
          });
      }
    },
    backToList() {
      localStorage.removeItem("venda");
      if (this.isMobile) {
        this.$router.push("/home");
      } else {
        this.$router.push("/vendas");
      }
    },
    async getVenda() {
      await vendasApi
        .getById(this.tableName, this.$route.params.id)
        .then((result) => {
          if (result.data.length) {
            this.object = result.data[0];
          }
        });
    },
    openModal(modal) {
      this.showModal[modal] = true;
      this.filter = "";
    },
    closeModal(modal) {
      this.showModal[modal] = false;
      this.loading = false;
    },
    async getProdutos() {
      await baseApi
        .get("produtos")
        .then((result) => {
          this.produtos = result.data;
        })
        .catch();
    },
    addProduto(produto) {
      const newProduto = {
        ID_PRODUTO: produto.ID,
        QUANTIDADE: 1,
        VALOR: parseFloat(produto.VALOR).toFixed(2),
        TOTAL: parseFloat(1 * produto.VALOR).toFixed(2),
        HASH: this.object.HASH,
        NOME: produto.NOME,
        IMAGEM: produto.IMAGEM,
        IMAGEM_URL: produto.IMAGEM_URL,
        INDEX: parseInt(this.object.produtosVendas.length),
        CATEGORIA: produto.CATEGORIA,
        ESTOQUE: produto.ESTOQUE,
        FRACIONADO: produto.FRACIONADO,
        UNIDADE: produto.UNIDADE,
        CODIGO_PRODUTO: produto.CODIGO,
        CUSTO: produto.CUSTO,
      };

      this.object.produtosVendas.push(newProduto);
      this.totalVenda();
      this.calcularParcelas();
      localStorage.setItem("venda", JSON.stringify(this.object));
      ShowToastMixin.showToast(`${produto.NOME} adicionado`, "positive");
      this.closeModal("produtos");
    },
    removeProdutoVenda({ produto }) {
      const index = this.object.produtosVendas.findIndex((p) => {
        return p.INDEX === produto.INDEX;
      });

      this.object.produtosVendas.splice(index, 1);
      this.totalVenda();
      this.calcularParcelas();
      localStorage.setItem("venda", JSON.stringify(this.object));
    },
    async totalVenda() {
      if (this.object.produtosVendas?.length > 0) {
        let valores = [];
        this.object.produtosVendas.forEach((produto) => {
          valores.push(produto.VALOR * produto.QUANTIDADE);
        });
        const newTotal = valores.reduce((a, b) => a + b, 0);
        this.object.SUB_TOTAL = newTotal.toFixed(2);

        this.object.TOTAL = (
          this.object.SUB_TOTAL - this.object.DESCONTO
        ).toFixed(2);
      } else {
        this.object.SUB_TOTAL = 0;
      }

      this.calcularParcelas();

      if (
        parseFloat(this.object.TOTAL) >
          parseFloat(this.limite.valor).toFixed(2) &&
        this.object?.produtosVendas?.length > 0 &&
        this.object.ID_CLIENTE &&
        (this.object.TIPO_PAGAMENTO === "DUPLICATA" ||
          this.object.TIPO_PAGAMENTO === "CREDIÁRIO")
      ) {
        ShowToastMixin.showToast(
          "Cliente estourou o limite de compras",
          "warning"
        );
      }
    },
    calcularParcelas() {
      if (
        this.object.TIPO_PAGAMENTO === "DUPLICATA" ||
        this.object.TIPO_PAGAMENTO === "CREDIÁRIO"
      ) {
        this.object.VALOR_PARCELA = parseFloat(
          this.object.TOTAL / this.object.PARCELAS
        ).toFixed(2);
      }
    },
    async openModalParcelas() {
      this.loading = false;
      await crediarioApi
        .getByHashVenda("crediario", this.object.HASH)
        .then(async (response) => {
          if (response.data.length) {
            this.parcelas = await response.data;
            this.showModal.parcelas = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async atualizarParcelas(parcelas) {
      parcelas.forEach(async (parcela) => {
        parcela.ID_CLIENTE = this.object.ID_CLIENTE;
        await baseApi.update("crediario", parcela);
      });
      this.backToList();
    },
    async setLimiteCliente() {
      const limite = await this.clientes.filter((el) => {
        if (el.ID_CLIENTE === this.object.ID_CLIENTE) {
          this.object.CLIENTE = el.NOME;
          this.object.ID_CLIENTE = el.ID_CLIENTE;
          return el;
        }
      });

      this.limite = {
        valor: parseFloat(limite[0]?.LIMITE | 0),
        prazo: parseInt(limite[0]?.PRAZO | 14),
      };
    },
    addCliente() {
      baseApi
        .insert("clientes", {
          NOME: this.object.CLIENTE,
        })
        .then(() => {
          ShowToastMixin.showToast(
            `Cliente ${this.object.CLIENTE} adicionado(a) aos seus clientes`,
            "positive"
          );
          this.backToList();
        });
    },
    async openRecibo() {
      const route = this.$router.resolve({
        name: "recibo-venda",
        query: {
          data: JSON.stringify(this.object),
          tipo: this.loja.TAMANHO_IMPRESSAO,
        },
        params: {
          data: JSON.stringify(this.object),
          tipo: this.loja.TAMANHO_IMPRESSAO,
        },
      });

      window.open(route.href, "_blank");
    },
    calcularTroco() {
      this.troco =
        parseFloat(this.object.DINHEIRO, 0) +
          parseFloat(this.object.PIX, 0) +
          parseFloat(this.object.CARTAO_CREDITO, 0) +
          parseFloat(this.object.CARTAO_DEBITO, 0) -
          parseFloat(this.object.TOTAL, 0) || 0;
    },
    async confirmarTroco() {
      //this.loading = true;

      try {
        this.object.produtosVendas.forEach((produto) => {
          delete produto.CATEGORIA;
          delete produto.IMAGEM;
          delete produto.IMAGEM_URL;
          delete produto.INDEX;
          delete produto.ESTOQUE;
          delete produto.FABRICANTE;
          delete produto.CUSTO;
          produto.HASH = this.object.HASH;
        });

        this.object.TROCO = this.troco;

        if (this.edit) {
          this.object.PRAZO = this.limite.prazo;
          await vendasApi
            .update(this.tableName, this.object)
            .then(async () => {
              ShowToastMixin.showToast(
                "Venda alterada com sucesso",
                "positive"
              );

              localStorage.removeItem("venda");
              await this.backToList();
            })
            .catch((error) => {
              ShowToastMixin.showToast(error.message, "negative");
              this.loading = false;
            });
        } else {
          this.object.PRAZO = this.limite.prazo;

          await vendasApi
            .insert(this.tableName, this.object)
            .then(async () => {
              ShowToastMixin.showToast(
                "Venda realizada com sucesso",
                "positive"
              );

              this.backToList();
              if (this.loja.IMPRESSAO_VENDA) {
                this.openRecibo();
              }
              localStorage.removeItem("venda");
            })
            .catch((error) => {
              ShowToastMixin.showToast(error.message, "negative");
              this.loading = false;
            });
        }
      } catch (error) {
        alert(String(error));
      }
    },
  },
};
</script>
