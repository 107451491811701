import axios from "axios";

const baseUrl = `${process.env.VUE_APP_API_BASE_URL}/crediario`;
import helpers from "../../helpers/session";
const user = helpers.getCurrentUser();
const headersJson = {
  Authorization: "Basic bWluaXBkdi5saW5lYXNmdC5jb20uYnI6QXBwQDIwMjA=",
  "Content-Type": "application/json",
  "X-Tenant": user?.tenant,
  "X-User": user?.usuario,
};

export default {
  get: async (table, order = "VENCIMENTO", data_ini, data_fim) => {
    var data = JSON.stringify({
      table,
      order,
      data_ini,
      data_fim,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  getById: async (table, id) => {
    var data = JSON.stringify({
      table,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/id/${id}`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  insert: async (table, object) => {
    var data = JSON.stringify({
      table: table,
      object: object,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/insert`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  update: async (table, object) => {
    var data = JSON.stringify({
      table: table,
      object: object,
    });

    const response = await axios({
      method: "PUT",
      url: `${baseUrl}/update`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  remove: async (table, object) => {
    var data = JSON.stringify({
      table: table,
      object: object,
    });

    const response = await axios({
      method: "DELETE",
      url: `${baseUrl}/remove`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  quitar: async (table, hash) => {
    var data = JSON.stringify({
      table: table,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/quitar/${hash}`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  getByHashVenda: async (table, hash) => {
    var data = JSON.stringify({
      table,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/venda/${hash}`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  receberParcial: async (table, object) => {
    var data = JSON.stringify({
      table,
      object,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/parcial`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
  vencidos: async (table, data_ini) => {
    var data = JSON.stringify({
      table,      
      data_ini,
    });

    const response = await axios({
      method: "POST",
      url: `${baseUrl}/vencidos`,
      data: data,
      headers: headersJson,
    });

    return response.data;
  },
};
