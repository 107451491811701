<template>
  <div>
    <div v-for="produto in produtos" :key="produto">
      <q-card-section class="text-center" v-if="type === 'single'">
        <span :style="`font-size: 14px`" class="q-mt-md"
          >CÓDIGO {{ produto.CODIGO }}
        </span>
        <br />
        <br />
        <QrcodeVue
          :value="String(produto.CODIGO)"
          :size="100"
          level="L"
          render-as="svg"
        />
        <br />
        <span :style="`font-size: 14px`">{{
          produto?.NOME?.toUpperCase()
        }}</span>
        <br />
        <strong :style="`font-size: 14px`"
          >R${{ parseFloat(produto?.VALOR).toFixed(2) }}</strong
        >
      </q-card-section>
      <q-card-section class="text-center" v-if="type === 'multiple'">
        <div class="row">
          <div class="col text-center">
            <span :style="`font-size: 8px`" class="q-mt-md"
              >CÓDIGO {{ produto.CODIGO }}
            </span>
            <br />
            <QrcodeVue
              :value="String(produto.CODIGO)"
              :size="75"
              level="L"
              render-as="svg"
            />
            <br />
            <span :style="`font-size: 8px`">{{
              produto?.NOME?.toUpperCase()
            }}</span>
            <br />
            <strong :style="`font-size: 8px`"
              >R${{ parseFloat(produto?.VALOR).toFixed(2) }}</strong
            >
          </div>
          <div class="col text-center">
            <span :style="`font-size: 8px`" class="q-mt-md"
              >CÓDIGO {{ produto.CODIGO }}
            </span>
            <br />
            <QrcodeVue
              :value="String(produto.CODIGO)"
              :size="75"
              level="L"
              render-as="svg"
            />
            <br />
            <span :style="`font-size: 8px`">{{
              produto?.NOME?.toUpperCase()
            }}</span>
            <br />
            <strong :style="`font-size: 8px`"
              >R${{ parseFloat(produto?.VALOR).toFixed(2) }}</strong
            >
          </div>
        </div>
        <br />
        <br />
        <div class="row">
          <div class="col text-center">
            <span :style="`font-size: 8px`" class="q-mt-md"
              >CÓDIGO {{ produto.CODIGO }}
            </span>
            <br />
            <QrcodeVue
              :value="String(produto.CODIGO)"
              :size="75"
              level="L"
              render-as="svg"
            />
            <br />
            <span :style="`font-size: 8px`">{{
              produto?.NOME?.toUpperCase()
            }}</span>
            <br />
            <strong :style="`font-size: 8px`"
              >R${{ parseFloat(produto?.VALOR).toFixed(2) }}</strong
            >
          </div>
          <div class="col text-center">
            <span :style="`font-size: 8px`" class="q-mt-md"
              >CÓDIGO {{ produto.CODIGO }}
            </span>
            <br />
            <QrcodeVue
              :value="String(produto.CODIGO)"
              :size="75"
              level="L"
              render-as="svg"
            />
            <br />
            <span :style="`font-size: 8px`">{{
              produto?.NOME?.toUpperCase()
            }}</span>
            <br />
            <strong :style="`font-size: 8px`"
              >R${{ parseFloat(produto?.VALOR).toFixed(2) }}</strong
            >
          </div>
        </div>
      </q-card-section>
    </div>
  </div>
</template>
<script>
import QrcodeVue from "qrcode.vue";
export default {
  name: "Etiqueta",
  components: {
    QrcodeVue,
  },
  data() {
    return {
      produtos: JSON.parse(this.$route.query.data),
      loja: {},
      tipo: "",
      type: "single",
      pageSettings: {
        fontSize: "14px",
      },
    };
  },
  async created() {
    await this.getLoja();

    this.setFontSize();
    1;
    this.printPage();
  },
  methods: {
    printPage() {
      window.print();
    },
    dateFormat(date) {
      return formaters.receiptDate(date);
    },
    async getLoja() {
      this.loja = await JSON.parse(localStorage.getItem("loja"));
      this.tipo = this.$route.query.tipo;
      this.type = this.$route.query.type;
    },
    setFontSize() {
      switch (this.loja?.TAMANHO_IMPRESSAO) {
        case "58mm":
          this.pageSettings.fontSize = "9px";
          break;

        case "80mm":
          this.pageSettings.fontSize = "11px";
          break;

        default:
          this.pageSettings.fontSize = "14px";
          break;
      }
    },
  },
};
</script>
